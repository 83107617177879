import { Component, OnInit } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { Tile as TileLayer, Vector, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Icon, Stroke, Style } from 'ol/style';
import OSM from 'ol/source/OSM';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service ';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  map;
  youtubeUrl = 'https://www.youtube.com/watch?v=WQFjZymnF3M';
  youtubeVideoId = 'WQFjZymnF3M';
  chicago;
  vectorSource;
  vectorLayer;
  rasterLayer;
  london: any;
  madrid: any;
  value = "";
  searchId = null;
  details = null;
  constructor(private userService: UserService, private route: ActivatedRoute,
    private notifyService: NotificationService,
  ) {
    if (this.route.snapshot.params.id) {
      this.searchId = this.route.snapshot.params.id;
      this.value = this.searchId;

    }
    else
      console.log("ok")

  }

  ngOnInit() {
    const tag = document.createElement('script');
    const container = document.getElementById('popup'); container.hidden = true;
    // this.userService.getUserMapMarks().subscribe(
    //   async value => {
    //     console.log(value);
    //     this.initilizeMap(value,null);
    //     // if(this.route.snapshot.params.id)
    //     // {
    //     //   this.onChange(this.route.snapshot.params.id,true);this.onChange(this.route.snapshot.params.id,false);
    //     // }

    //   },
    //   error1 => {

    //     console.log(error1);
    //   }
    // ); 
    this.userService.getUserByEmailPassword("telefret", "Telefret@2020").subscribe(
      value => {
        console.log(value);
        // this.ngxService.stop();

        // alert("ok")

        if (value.hasOwnProperty('id')) {
          this.notifyService.showSuccess("Bienvenue!", "TELEFRET")
          this.userService.addItem(JSON.stringify(value));
          this.userService.user = value;
          this.userService.isAuth = true;
          // alert(this.route.snapshot.params.id)
          if (this.route.snapshot.params.id) {
            this.onChange(this.route.snapshot.params.id, true); this.onChange(this.route.snapshot.params.id, false);
          }
        }
        else {
          this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET");
          return;
        }
      },
      error => {

        this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET")
      }
    )


  }

  initilizeMap(ListMarker, filter, direction) {


    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');
    container.hidden = false;
    console.log("container", container)
    console.log("content", content)
    console.log("closer", closer)


    var arrayMarker = []
    const divmap = document.getElementById('map');

    ListMarker.map(element => {
      if (filter && filter != '' && element.idRealTimeRecord) {
        divmap.innerHTML = '<label>Loading..</label>';
        this.valueFind = true;
        console.log("filterrrrr", (element.idRealTimeRecord + "").includes(filter))

        console.log("*******************************************",[element.coordinate.lng, element.coordinate.lat])

        var feature = new Feature({
          type: 'click',
          geometry: new Point(fromLonLat([element.coordinate.lng, element.coordinate.lat]))
        });
        feature.setStyle(new Style({
          image: new Icon(({
            scale: 0.7,
            rotateWithView: false,
            anchor: [0.5, 1],
            opacity: 1,
            src: '../../../../../assets/markeurnew_64x64.png'
          }))
        }));
        feature.addEventListener('click', event => {
          console.log(event)
          return true
        });

     
          var feature1 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat( ListMarker[0].Chargement.longitude),parseFloat( ListMarker[0].Chargement.latitude) ]))
          });
          feature1.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: '../../../../../assets/dotgreen1.png'
            }))
          }));
          feature1.addEventListener('click', event => {
            console.log(event)
            return true
          });
          arrayMarker.push(feature1)

          var feature2 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat( ListMarker[0].Chargement.longitude1),parseFloat( ListMarker[0].Chargement.latitude1)]))
          });
          feature2.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: '../../../../../assets/dotred1.png'
            }))
          }));
          feature2.addEventListener('click', event => {
            console.log(event)
            return true
          });
          arrayMarker.push(feature2)

         arrayMarker.push(feature)
      } 
      
      else 
      
      if (filter == null || filter == '') {




          // var routeLayer = new Vector({
          //    style: new Style({
          //      stroke:new Stroke({ color: "hsl(205, 100%, 50%)", width: 4 })
          //    })
          //  });

          var feature1 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat( ListMarker[0].Chargement.longitude),parseFloat( ListMarker[0].Chargement.latitude) ]))
          });
          feature1.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: '../../../../../assets/dotgreen1.png'
            }))
          }));
          feature1.addEventListener('click', event => {
            console.log(event)
            return true
          });
          arrayMarker.push(feature1)

          var feature2 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat( ListMarker[0].Chargement.longitude1),parseFloat( ListMarker[0].Chargement.latitude1)]))
          });
          feature2.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: '../../../../../assets/dotred1.png'
            }))
          }));
          feature2.addEventListener('click', event => {
            console.log(event)
            return true
          });
          arrayMarker.push(feature2)


        
        arrayMarker.push(feature)
      }

    })

    // this.chicago = new Feature({
    //   geometry: new Point(fromLonLat([	-87.623177, 41.881832]))
    // });

    // this.chicago.setStyle(new Style({
    //   image: new Icon(({
    //     color: '#8959A8',
    //     crossOrigin: 'anonymous',
    //     src: 'assets/vectorpoint.svg',
    //     imgSize: [20, 20]
    //   }))
    // }));
    // arrayMarker.push(this.chicago)

    // this.london = new Feature({
    //   geometry: new Point(fromLonLat([-0.12755, 51.507222]))
    // });

    // this.madrid = new Feature({
    //   geometry: new Point(fromLonLat([-3.683333, 40.4]))
    // });

    // this.london.setStyle(new Style({
    //   image: new Icon(({
    //     color: '#4271AE',
    //     crossOrigin: 'anonymous',
    //     src: 'assets/vectorpoint.svg',
    //     imgSize: [20, 20]
    //   }))
    // }));

    // this.madrid.setStyle(new Style({
    //   image: new Icon(({
    //     color: [113, 140, 0],
    //     crossOrigin: 'anonymous',
    //     src: 'assets/dot.png',
    //     imgSize: [20, 20]
    //   }))
    // }));

    this.vectorSource = new VectorSource({
      features: arrayMarker
    });

    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });




    const overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    divmap.innerHTML = null;

    this.map = new Map({
      target: 'map',
      layers: [new TileLayer({
        source: new OSM() as any
      }),
      this.vectorLayer],
      overlays: [overlay],
      view: new View({
        center: fromLonLat([ListMarker[0].coordinate.lng, ListMarker[0].coordinate.lat]),
        zoom: 7
      })
    });

    var that = this;

    this.map.on('click', function (evt) {
      var f = that.map.forEachFeatureAtPixel(
        evt.pixel,
        function (ft, layer) { return ft; }
      );
      if (f && f.get('type') == 'click') {
        var geometry = f.getGeometry();
        var coord = geometry.getCoordinates();
        const coordinate = coord;
        const hdms = toStringHDMS(toLonLat(coordinate));
        var value = that.userService.ListMarker.find(x => {
          return ((x.coordinate.lng + "").substr(0, 5) == (toLonLat(coordinate)[0] + "").substr(0, 5)) && ((x.coordinate.lat + "").substr(0, 5) == (toLonLat(coordinate)[1] + "").substr(0, 5))

        })
        console.log("***********************************1111",value)
        if(value){
          var city;
          var speed = value.speed;
          var date = new Date(value.recordTime)
          var dateStr =
            ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
            ("00" + date.getDate()).slice(-2) + "/" +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
          fetch('https://telefret.com/api_redirect/?lon=' + toLonLat(coordinate)[0] + '&lat=' + toLonLat(coordinate)[1])
            .then(function (response) {
              return response.json();
            }).then(function (json) {
              city = json.display_name;
              console.log("valuevaluevaluevaluevaluevaluevaluevalue", value);
              content.innerHTML = `
                
            <p> <b> Adresse:</b> ${city}
          
              </p>
              <p> <b> Date:</b> ${value.recordTime ? dateStr : "Inconnu"}
          
              </p>
              <p>
              <b>  Vitesse: </b> ${speed}
                </p>`;
              overlay.setPosition(coordinate);
            });
        }else{

          var date = new Date()
          var dateStr =
            ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
            ("00" + date.getDate()).slice(-2) + "/" +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
          fetch('https://telefret.com/api_redirect/?lon=' + toLonLat(coordinate)[0] + '&lat=' + toLonLat(coordinate)[1])
            .then(function (response) {
              return response.json();
            }).then(function (json) {
              city = json.display_name;
              content.innerHTML = `
                
            <p><b> Adresse:</b> ${city}
          
              </p>
              <p> <b>Date:</b> ${ dateStr }
          
              </p>
              `;
              overlay.setPosition(coordinate);

            });

          }
       



      } else {
        closer.click();
      }

    });





    this.map.on('pointermove', function (e) {
      if (e.dragging) { return; }

      var pixel = that.map.getEventPixel(e.originalEvent);
      var hit = that.map.hasFeatureAtPixel(pixel);
      console.log(that.map.getTarget())
      // that.map. .cursor = hit ? 'pointer' : '';
    });
    // this.map.on('singleclick', function (evt: any) {
    //   const coordinate = evt.coordinate;
    //   const hdms = toStringHDMS(toLonLat(coordinate));

    //   content.innerHTML = '<p>Current coordinates are :</p><code>' + hdms +
    //     '</code>';
    //   overlay.setPosition(coordinate);
    // });
    //   const map1= this.map;
    //   map1.on('singleclick', function(e) {
    //     var feature = map1.forEachFeatureAtPixel(e.pixel, function(feature) {
    //     return feature;
    //     });
    //     if(feature && feature. n.marker) {
    //         feature.n.marker.label.message = "CLICKED!"; // Do something smarter here ...
    //     }
    // });

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
  }


  valueFind = false;
  onChange(val, toast) {
    console.log(val)
    var that=this
    var direction = { from: null, to: null };
    if (val != "") {
      this.userService.getUserMapMarks(val).subscribe(
        async value => {
          console.log(value);
          this.details = value ? value : null;

          var array = [];
          array.push(value);
          that.userService.ListMarker = array;
          that.initilizeMap(array, val, direction);         

        },
        error1 => {
          if (toast)
            this.notifyService.showError(error1.error, "TELEFRET");

          console.log(error1);
        }
      );
    } else if (toast)
      this.notifyService.showError("Veillez entrer une valeur!", "TELEFRET");


  }
  onChangeValue(val) {
    this.value = val;
  }

  reverseGeocode(coords) {
    fetch('http://nominatim.openstreetmap.org/reverse?format=json&lon=' + coords[0] + '&lat=' + coords[1])
      .then(function (response) {
        return response.json();
      }).then(function (json) {
        console.log(json);
      });
  }

}
