<!-- <app-home-header></app-home-header> -->
<link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/ol3/3.5.0/ol.min.css" type="text/css">
<div class="bradcam_area bradcam_bg_1" [style.padding]="!details?' 182px 0 102px':'20px'" style="text-align: -webkit-center;padding: 20px;">
  <div class="row col-md-7" 
  *ngIf="details"
  style="  
      background-image: linear-gradient(rgb(255 255 255 / 50%), rgb(255 255 255 / 50%)), url(https://telefret.com/assets/img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
  background-color: white;
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  margin: 25px;
  border-radius: 10px;
  text-align: start;
  border: 1px solid #cccccc;
  bottom: 12px;
  min-width: 280px;margin-left: auto!important;margin-right: auto!important; " >
    <div class="col-md-6">
      <p style="color: black!important"> Expéditeur : {{ (details?.Chargeur?.r_s!=""&&details?.Chargeur?.r_s!=" ")?details?.Chargeur?.r_s:(details?.Chargeur?.nom + " "+details?.Chargeur?.prenom) }}</p>
      <p style="color: black!important"> Type de marchandise: {{ details?.Chargement?.marchandise?details?.Chargement?.marchandise:"" }} </p>
      <!-- <p style="color: black!important"> Conditionnement: {{ details?.r_s }} </p> -->
      <p style="color: black!important"> Tonnage: {{ details?.Chargement?.poid?details?.Chargement?.poid:"" }} </p>
      <p style="color: black!important"> Détails sur la 
        marchandise:  {{ details?.Chargement?.details_march?details?.Chargement?.details_march:"" }} </p>

      <p style="color: black!important">Adresse de chargement : {{ details?.Chargement?.adresse_charg+" "+details?.Chargement?.ville_charg }}</p>
      <p style="color: black!important">Date de chargement: {{ details?.Chargement?.date_charg }}</p>

    </div>
    <div class="col-md-6">
      <p style="color: black!important"> Transporteur:  {{ (details?.Transporteur?.r_s!=""&&details?.Transporteur?.r_s!=" ")?details?.Transporteur?.r_s:(details?.Transporteur?.nom + " "+details?.Transporteur?.prenom) }}  </p>
      <p style="color: black!important"> Matricule du véhicule:  {{ details?.Abonnement?.matricule }} </p>
      <!-- <p style="color: black!important"> Conditionnement: </p> -->
      <p style="color: black!important"> Marque: {{details?.Abonnement?.marque }}  </p>
      <p style="color: black!important"> Nom du Chauffeur: {{ details?.Abonnement?.nom_chauffeur!=""?details?.Abonnement?.nom_chauffeur:"/" }}</p>
      <p style="color: black!important">Contact du Chauffeur : {{ details?.Abonnement?.telephone_chauffeur!=""?details?.Abonnement?.telephone_chauffeur:details?.Abonnement?.telephone }}</p>
      <p style="color: black!important">Adresse de livraison :  {{ details?.Chargement?.adresse_liv+" "+details?.Chargement?.ville_liv }}</p>
      <p style="color: black!important">Date de livraison: {{ details?.Chargement?.date_liv }}</p>

    </div>

    <div class="col-md-6">

    <!-- <a style="margin-left: auto!important;margin-right: auto!important;" href="https://telefret.com/invoice2.php?id_charg={{ details?.Chargement?.id_charg}}">Consulter
      la fiche du Chargement</a> -->
    </div>
    <div class="col-md-6">

  <a style="margin-left: auto!important;margin-right: auto!important;" href="https://telefret.com/invoice1.php?id_charg={{ details?.Chargement?.id_charg }}">Consulter
      les fiches de transport</a>
    </div>
  </div>
  <div class="row">
   

    <div class="col-xl-12" style="text-align: -webkit-center;margin-left: auto!important;margin-right: auto!important;">
      <div class="text-align-center">
        <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
          <div class="col-md-12">
            <h5 for="" style="color: white;">Saisissez le N° d'expédition</h5>
          </div>
        </div>
        <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
          <div class="col-md-12">
            <button type="button" (click)="onChange(value,true);onChange(value,false);" style="
            position:absolute; right: 10px
            " class="btn btn-success">
              <i class="fa fa-search my-float"></i>
            </button>
            <input [ngModel]="value" (ngModelChange)="onChangeValue($event)" type="search" id="form1"
              class="form-control" />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrapper ">

  <div id="map" class="{{!valueFind?'demo-area1':'demo-area'}} map"></div>

</div>

<div id="popup" class="ol-popup">
  <a href="#" id="popup-closer" class="ol-popup-closer"></a>
  <div id="popup-content"></div>
</div>